import React, { useRef as UseRef } from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import Slider from "react-slick"
import ProjectThumb from "../components/ProjectThumb"

const graphicProject = ({ pageContext }) => {
  console.log(pageContext)

  const sliderRef = UseRef()
  const projects = require("../../data/projects.json")

  //Slick slider config
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  //Slick slider functions
  const nextSlide = () => {
    sliderRef.current.slickNext()
  }

  const prevSlide = () => {
    sliderRef.current.slickPrev()
  }

  return (
    <Layout>
      <section id="graphic-project-section" style={sectionStyle}>
        <div
          className="container-fluid showcase-container "
          style={containerStyle}
        >
          <img
            src="/main-arrow-back.svg"
            className="main-back"
            style={backStyle}
            onClick={() => navigate("/graphicdesign")}
          ></img>

          <div className="graphic-showcase-wrapper" style={showcaseStyle}>
            <div className="graphic-image-left-wrapper" style={imageLeftStyle}>
              {pageContext.images.length > 1 && (
                <>
                  {/* <img className = 'arrow right' src = '/icons/arrow-right.svg'  onClick={() => nextSlide()}></img> */}
                  {/* <img className = 'arrow left' src = '/icons/arrow-left.svg'  onClick={() => prevSlide()}></img> */}

                  <svg
                    className={`arrow right ${pageContext.slug}`}
                    onClick={() => nextSlide()}
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.982"
                    height="47.321"
                    viewBox="0 0 25.982 47.321"
                  >
                    <g
                      id="noun-back-860378"
                      transform="translate(25.982 47.321) rotate(180)"
                    >
                      <path
                        id="noun-back-860378-2"
                        data-name="noun-back-860378"
                        d="M198,44.228,217.689,24.54a2.322,2.322,0,1,0-3.283-3.283L193.077,42.586a2.325,2.325,0,0,0,0,3.283L214.405,67.2a2.322,2.322,0,1,0,3.283-3.283Z"
                        transform="translate(-192.398 -20.566)"
                        fill="#fff"
                        fill-rule="evenodd"
                      />
                    </g>
                  </svg>

                  <svg
                    className={` arrow left ${pageContext.slug}`}
                    onClick={() => prevSlide()}
                    id="noun-back-860378"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.982"
                    height="47.321"
                    viewBox="0 0 25.982 47.321"
                  >
                    <path
                      id="noun-back-860378-2"
                      data-name="noun-back-860378"
                      d="M198,44.228,217.689,24.54a2.322,2.322,0,1,0-3.283-3.283L193.077,42.586a2.325,2.325,0,0,0,0,3.283L214.405,67.2a2.322,2.322,0,1,0,3.283-3.283Z"
                      transform="translate(-192.398 -20.566)"
                      fill="#fff"
                      fill-rule="evenodd"
                    />
                  </svg>
                </>
              )}

              <Slider
                {...sliderSettings}
                style={{ height: "100%" }}
                ref={sliderRef}
              >
                {pageContext.images.map((image, i) => (
                  <div style={{ height: "100%" }} className="slick-container">
                    {image.includes("mp4") || image.includes("MP4") ? (
                      <video
                        style={imageStyle}
                        className={`${pageContext.slug} ${pageContext.slug}-${i}`}
                        controls
                        autoplay
                      >
                        <source src={`${image}`} />
                      </video>
                    ) : (
                      <img
                        src={`${image}`}
                        style={imageStyle}
                        className={`graphic-image ${pageContext.slug} ${pageContext.slug}-${i} img-fluid`}
                        loading="eager"
                      ></img>
                    )}
                  </div>
                ))}
              </Slider>
            </div>

            <div
              className="graphic-description-wrapper"
              style={descriptionWrapperStyle}
            >
              <div
                className="graphic-description-container"
                style={descriptionContainerStyle}
              >
                <h3 style={titleStyle} className="graphic-project-title">
                  {pageContext.title}
                </h3>
                <h6 className="graphic-project-subtitle" style={categoryStyle}>
                  {pageContext.category}
                </h6>
                <div className="graphic-description" style={descriptionStyle}>
                  <p
                    className="m-0 showcase-paragraph jost"
                    style={paragraphStyle}
                  >
                    {pageContext.description}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="more-projects-section" style={moreSectionStyle}>
            <div className="row">
              {projects.map(project => {
                return project.title !== pageContext.title ? (
                  <ProjectThumb project={project} />
                ) : (
                  <></>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
const sectionStyle = {
  minHeight: "100vh",
}

const containerStyle = {
  paddingLeft: "70px",
  paddingRight: "70px",
}

const headerWrapperStyle = {
  backgroundColor: "#f7f7f7",
}
const headingStyle = {
  fontSize: "55px",
  width: "100%",
  marginTop: "60px",
  paddingBottom: "40px",
  color: "#474849",
  fontWeight: "normal",
}

const showcaseStyle = {
  width: "1005",
  height: "700px",
  marginTop: "40px",
  display: "flex",
  position: "relative",
}

const imageLeftStyle = {
  height: "100%",
  width: "70%",
  position: "relative",
}

const descriptionWrapperStyle = {
  width: "30%",
  height: "100%",
  backgroundColor: "#efefef",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}

const descriptionStyle = {
  height: "300px",
  width: "280px",
  overflow: "auto",
  paddingRight: "30px",
}

const descriptionContainerStyle = {
  width: "280px",
}

const titleStyle = {
  fontSize: "38px",
  marginBottom: "10px",
  color: "#4748490",
}

const paragraphStyle = {
  fontSize: "20px",
  lineHeight: "22px",
  color: "#474849",
}

const categoryStyle = {
  color: "#FD0D1b",
  fontSize: "24px",
  marginBottom: "30px",
}

const imageStyle = {
  height: "100%",
  width: "100%",
  objectFit: "cover",
}

const moreSectionStyle = {
  marginTop: "100px",
}

const moreProjectStyle = {
  width: "100%",
  height: "450px",
  backgroundColor: "lightgray",
}

const backStyle = {
  marginTop: "70px",
  height: "30px",
}
export default graphicProject
