import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const ProjectThumb = ({project}) => {


    return (
        <div className = 'col-lg-6 graphic-col mt-3'>
                    <Link to = {`/graphicdesign/${project.slug}`}>
                    <div className = 'graphic-overlay'>
                        <div className = 'overlay-text'>
                        <h2 className = 'graphic-hover-text'>VIEW</h2>
                        <span>{project.title}</span>
                        <img src = '/arrow.svg' className = 'graphic-arrow'></img>
                        </div>
                    </div>

                    <img src = {`${project.thumb}`} style = {imageStyle} loading ='lazy' className = 'project-thumb'></img>
                {/* <StaticImage
                            src= {`${project.images[0]}`}
                            width={'500px'}
                            height = {'500px'}
                            quality={100}
                            formats={["auto", "webp", "avif"]}
                            alt="Logo"
                            style = {imageStyle}
                            
                            /> */}
                            </Link>
                </div>
    )
}

const imageStyle = {
    width:'100%',
    height:'450px',
}

export default ProjectThumb